import * as React from "react";

import { useStyles } from "./Parallax.styling";

const Parallax = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.parallax}>{children}</div>;
};

const ParallaxGroup = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.parallaxGroup}>{children}</div>;
};

const ParallaxLayer = ({ children, depth, scale, base = false, padding }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.parallaxLayer}
      style={{
        transform: base
          ? `translateZ(0)`
          : `translateZ(${depth}px) scale(${scale})`,
        padding: padding,
      }}
    >
      {children}
    </div>
  );
};

export { Parallax, ParallaxGroup, ParallaxLayer };
