import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  card: {
    boxShadow: "none",
    maxWidth: 345,
    margin: "3.5rem 1rem",
    [theme.breakpoints.up("tablet")]: {
      maxWidth: 285,
    },
    [theme.breakpoints.up("desktop")]: {
      maxWidth: 300,
    },
  },
  cardTitle: {
    margin: "1rem 0",
    minHeight:'56px'
  },
  cardImageR: {
    borderRadius: "0 0 0 40px",
  },
  cardImageL: {
    borderRadius: "0 0 40px 0",
  },
  tile:{
    height: "100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
  },
  gridBody:{
    padding:"3.5rem 0",
    [theme.breakpoints.up("desktop")]: {
      padding:"3rem 0"
    }
  },
  link:{
    textDecoration:"none",
    color:"white",
  }
}));
