import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardImage: {
    backgroundPosition: "top !important",
  },
  heading: {
    textTransform: "uppercase",
    fontWeight:700,
    marginBottom:"3rem",
    color:"#5e5d5d"
  },
  bannerContent: {
    position: "absolute",
    bottom: 0,
    maxWidth: "690px",
    display:"flex",
    flexDirection:"column",
    alignItems:"start",
    margin:"0 0 15% 5%",
    [theme.breakpoints.up('tablet')]: {
      margin:"0 0 5% 5%",
    },
  },
  bannerButton:{
    fontSize:"1.25rem",
    padding: "1.15rem 1.5rem"
  },
  subHeading:{
    maxWidth:"70%",
    color:"#767474"
  }
}));
