import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  parallax: {
    // perspectiveOriginX: "100%",
    perspective: "1px",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  parallaxGroup: {
    position: "relative",
    height: "100vh",
    transformStyle: "preserve-3d",
  },
  parallaxLayer: {
    // perspectiveOriginX: "100%",
     padding: "100vh 0",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));
