import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Layout } from "../components/Layout";
import { HeroBanner } from "../components/HeroBanner";
import { Parallax, ParallaxLayer } from "../components/Parallax/Parallax";
import { GridList, GridListTile } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import Seo from "../components/Seo/Seo";

import CleaningImg from "../images/hero-banner/cleaning_gloves.jpg";
import CleaningImg2x from "../images/hero-banner/cleaning_tools.jpg";
import GardeningImg from "../images/services-page/gardening_service.jpg";
import ConciergeImg from "../images/services-page/concierge_service.jpg";
import MoppingImg from "../images/services-page/mopping.jpeg";
import DishesImg from "../images/services-page/lady_cleaning_dishes.jpeg";

import { useStyles } from "../pagesStyles/services.styling";

const Services = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  return (
    <Layout navbarPosition="absolute">
      <Seo
        title="Services"
        description="Areas Covered for House Keeping Services: Solihull and Surrounding areas including: Dorridge, Balsall Common, Hampton in Arden, Lapworth, Hockley Heath, Henley in Arden, Tanworth in Arden, Kenilworth"
      />
      <Parallax>
        <ParallaxLayer padding={0} depth={-2} scale={3}>
          <HeroBanner
            image={desktop ? CleaningImg : CleaningImg2x}
            alt="cleaning stairs"
            heading="Areas Covered for House Keeping Services"
            subHeading="Solihull and Surrounding areas including: Dorridge, Balsall Common, Hampton Arden, Lapworth, Hockley Heath, Henley in Arden, Tamworth in Arden, Kenilworth"
            headingBlack={!desktop}
          ></HeroBanner>
        </ParallaxLayer>
        <ParallaxLayer depth={-1} scale={2} base>
          <section style={{ backgroundColor: "white" }}>
            <GridList
              cellHeight="auto"
              className={classes.gridBody}
              cols={desktop ? 4 : 1}
            >
              <GridListTile classes={{ tile: classes.tile }}>
                <Card className={classes.card}>
                  <Slide direction="right" in timeout={700}>
                    <CardMedia
                      style={{ maxWidth: 345, height: "300px" }}
                      image={MoppingImg}
                      // alt={altTag}
                      classes={{ root: classes.cardImageL }}
                      component="img"
                    />
                  </Slide>
                  <Typography
                    align="center"
                    variant="h3"
                    classes={{ root: classes.cardTitle }}
                  >
                    {"Domestic/Office & Deep Cleaning"}
                  </Typography>
                  <Typography variant="body">
                    Helping you to keep your home and office clean and tidy.
                  </Typography>
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button color="primary" variant="contained" style={{marginRight:"0.5rem"}}>
                    <Link
                      className={classes.link}
                      to={"/services/domestic-cleaning"}
                    >
                      Home
                    </Link>
                  </Button>
                  <Button color="primary" variant="contained" style={{marginLeft:"0.5rem"}}>
                    <Link
                      className={classes.link}
                      to={"/services/office-cleaning"}
                    >
                      Office
                    </Link>
                  </Button>
                </div>
              </GridListTile>
              <GridListTile classes={{ tile: classes.tile }}>
                <Card className={classes.card}>
                  <Slide direction="right" in timeout={700}>
                    <CardMedia
                      style={{ maxWidth: 345, height: "300px" }}
                      image={DishesImg}
                      // alt={altTag}
                      classes={{ root: classes.cardImageL }}
                      component="img"
                    />
                  </Slide>
                  <Typography
                    align="center"
                    variant="h3"
                    classes={{ root: classes.cardTitle }}
                  >
                    {"House Keeping & Companionship"}
                  </Typography>
                  <Typography variant="body">
                    Offering support around the home from changing bed linen to
                    taking the dog for a walk along whilst offering some company
                    to brighten up your day.
                  </Typography>
                </Card>
                <Button color="primary" variant="contained">
                  <Link className={classes.link} to={"/services/house-keeping"}>
                    House Keeping
                  </Link>
                </Button>
              </GridListTile>
              <GridListTile classes={{ tile: classes.tile }}>
                <Card className={classes.card}>
                  <Slide direction="left" in timeout={700}>
                    <CardMedia
                      style={{ maxWidth: 345, height: "300px" }}
                      image={GardeningImg}
                      // alt={altTag}
                      classes={{ root: classes.cardImageR }}
                      component="img"
                    />
                  </Slide>
                  <Typography
                    align="center"
                    variant="h3"
                    classes={{ root: classes.cardTitle }}
                  >
                    {"Gardening"}
                  </Typography>
                  <Typography variant="body">
                    From mowing the lawn to trimming hedges to maintaining your
                    vegetable garden.
                  </Typography>
                </Card>
                <Button color="primary" variant="contained">
                  <Link className={classes.link} to={"/services/gardening"}>
                    Gardening
                  </Link>
                </Button>
              </GridListTile>
              <GridListTile classes={{ tile: classes.tile }}>
                <Card className={classes.card}>
                  <Slide direction="left" in timeout={700}>
                    <CardMedia
                      style={{ maxWidth: 345, height: "300px" }}
                      image={ConciergeImg}
                      // alt={altTag}
                      classes={{ root: classes.cardImageR }}
                      component="img"
                    />
                  </Slide>
                  <Typography
                    align="center"
                    variant="h3"
                    classes={{ root: classes.cardTitle }}
                  >
                    {"Concierge Services"}
                  </Typography>
                  <Typography variant="body">
                    Whether you need a plumber, electrician or a roofer we have
                    the necessary contacts and can arrange and oversee these
                    works for you.
                  </Typography>
                </Card>
                <Button color="primary" variant="contained">
                  <Link
                    className={classes.link}
                    to={"/services/concierge-services"}
                  >
                    Concierge Services
                  </Link>
                </Button>
              </GridListTile>
            </GridList>
          </section>
        </ParallaxLayer>
      </Parallax>
    </Layout>
  );
};

export default Services;
