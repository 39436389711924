import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CardMedia, Typography } from "@material-ui/core";
import { Slide } from "@material-ui/core";

import { useStyles } from "./HeroBanner.styling";

const HeroBanner = ({
  image,
  altTag,
  heading,
  subHeading,
  children,
  headingBlack,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  return (
    <>
      <CardMedia
        style={{ height: desktop ? "auto" : "100vh" }}
        image={image}
        alt={altTag}
        classes={{ root: classes.cardImage }}
        component="img"
      />
      <div className={classes.bannerContent}>
        {heading && (
          <Slide direction="up" in timeout={700}>
            <Typography
              variant="h2"
              classes={{ root: classes.heading }}
              style={{ color: headingBlack && "black" }}
            >
              {heading}
            </Typography>
          </Slide>
        )}
        {subHeading && (
          <Slide direction="up" in timeout={800}>
            <Typography
              variant="h3"
              classes={{ root: classes.subHeading }}
              style={{ color: headingBlack && "black" }}
            >
              {subHeading}
            </Typography>
          </Slide>
        )}
        {children}
      </div>
    </>
  );
};

export default HeroBanner;
